import './App.css';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Modal from '@mui/material/Modal';

const BACKEND_URL = process.env.NODE_ENV === 'development' ? "http://127.0.0.1:8080/api" : '/api'
const PLAYSTORE_URL = "https://play.google.com/store/apps/details?id=com.player.onlinevideoplayer"
const STREAM_STARTER_URL = 'https://fstreams.me/ovp-plugins/score808.us.json';

function App() {
  const [matchesList, setMatchesList] = useState([])
  const [selectedHttpLink, setSelectedHttpLink] = useState("");
  const handleClose = () => setSelectedHttpLink("");

  useEffect(() => {

    const getMatchesList = () => {
      const url = `${BACKEND_URL}/matches/list`

      return fetch(url).then(res => res.json())
    }

    getMatchesList().then(setMatchesList)

  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Modal
          open={selectedHttpLink !== ""}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              How to play the videos
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Do you have <a href={PLAYSTORE_URL}>"OVP App"</a> installed on your android device?
            </Typography>
            <br />
            <a rel="noreferrer" target="_blank" href={PLAYSTORE_URL}>
              <Button>No, take me to the download page</Button>
            </a>
            <a rel="noreferrer" target="_blank" href={`intent://#Intent;package=com.player.onlinevideoplayer;scheme=ovpp://${selectedHttpLink.replace('https://', '')}?streamextractorurl=${STREAM_STARTER_URL};end;`}>
              <Button variant='contained'>Yes, open this link in OVP</Button>
            </a>
          </Box>
        </Modal>

        {
          matchesList.map(match => <div style={{
            marginTop: 32,
            width: '90%',
            maxWidth: 800,
          }}>
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => {
                setSelectedHttpLink(match['httpLink'])
              }}
            >
              <Button style={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '90%',
                margin: 16
              }}>
                <div style={{
                  width: 100,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Avatar alt={match['home']} src={match['homeIcon']} />
                  <div>
                    {match['home']}
                  </div>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Typography fontWeight={700}>VS</Typography>
                  <Button variant="contained">Watch Now</Button>
                </div>
                <div style={{
                  width: 100,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Avatar alt={match['away']} src={match['awayIcon']} />
                  <div>
                    {match['away']}
                  </div>
                </div>

              </Button>
            </Card>

          </div>)
        }
      </header>
    </div>
  );
}

export default App;
